import {getInputState, removeInput} from "./productGrid";
import {onFind} from "@elements/init-modules-in-scope";
import {closest, on, findIn, find} from "@elements/dom-utils";

const defaultSelectors = {
    base: '.js-product-grid-quick-filter',
    container: '.js-product-grid',
    template: '.js-product-grid-quick-filter__template',
    text: '.js-product-grid-quick-filter__text',
    removeAllButton: '.js-product-grid-quick-filter__remove-all'
};

export function init(options = {}, selectors = defaultSelectors, templates = {}) {
    onFind(selectors.base, function (baseElement) {
        createQuickFilter(
            baseElement,
            {...options},
            {...defaultSelectors, ...selectors},
            {...templates}
        );
    });
}

export function createQuickFilter(baseElement, options = {}, selectors = defaultSelectors, templates = {}) {
    options = {
        ...defaultSelectors,
        ...options
    };

    selectors = {
        ...defaultSelectors,
        ...selectors
    };

    templates = {
        ...templates
    };

    let productGrid;

    if (baseElement.getAttribute('data-product-grid')) {
        productGrid = find(baseElement.getAttribute('data-product-grid'));
        if (!productGrid || !productGrid.length) {
            console.error('Can\'t find product grid with selector "' + baseElement.getAttribute('data-product-grid')
                + '" for quickfilter', baseElement);
        }

        return;
    } else {
        productGrid = closest(selectors.container, baseElement);
    }

    if (!productGrid) {
        console.error('Can\'t find product grid for quickfilter. Add a data-product-grid selector or place the element inside a ' + selectors.container, baseElement);
        return;
    }

    on('product-grid.changed', function () {
        renderQuickFilters(baseElement, productGrid);
    }, productGrid);

    renderQuickFilters(baseElement, productGrid);

    function flat(obj) {
        let {parent} = obj;
        let array = [];

        array.push(obj);

        if (parent) {
            parent.isParent = true;
            array = flat(parent).concat(array);
        }

        return array;
    }

    function renderQuickFilters(baseElement, productGrid) {
        baseElement.innerHTML = '';

        let quickFilter = [];
        if(_config.quickFilterHierarchy) {
            let {attributes, ...groups} = _config.quickFilterHierarchy;
            let filter = [...Object.values(groups).filter(x => !!Object.entries(x).length)];

            quickFilter = filter.map((group) => {return flat(group);});

            if (attributes) {
                attributes = Object.values(attributes).filter(x => !!Object.entries(x).length);
                quickFilter = quickFilter.concat([attributes]);
            }

            for (let i = 0; i < quickFilter.length; i++) {
                let group = [];
                quickFilter[i].forEach(function (filter) {
                    group.push(filter)
                });

                if(templates.linkTemplate) {
                    baseElement.innerHTML = templates.linkTemplate(group);
                }
            }
        }

        if(templates.defaultTemplate) {
            let filter = [];
            getInputState(productGrid).forEach(function (inputState) {
                if (!inputState.hidden) {
                    filter.push(inputState);
                }
            });

            baseElement.innerHTML = templates.defaultTemplate(filter);

            let quickFilterElement = findIn('.js-product-grid-quick-filter__item', baseElement);
            on('click', function () {
                if (quickFilterElement.getAttribute('data-filter-name').indexOf('[]')) {
                    removeInput(productGrid, quickFilterElement.getAttribute('data-filter-name'), quickFilterElement.getAttribute('data-filter-value'));
                } else {
                    removeInput(productGrid, quickFilterElement.getAttribute('data-filter-name'));
                }
            }, quickFilterElement);
        } else {
            getInputState(productGrid).forEach(function (inputState) {
                if (!inputState.hidden && inputState.value !== "$$EMPTY$$") {
                    let newButtonElement = createQuickFilter(inputState);

                    on('click', function () {
                        if (inputState.name.indexOf('[]')) {
                            removeInput(productGrid, inputState.name, inputState.value);
                        } else {
                            removeInput(productGrid, inputState.name);
                        }
                    }, newButtonElement);

                    baseElement.appendChild(newButtonElement);
                }
            });
        }

        // get the visible quickfilters
        let visibleFilters = [];
        getInputState(productGrid).forEach(function (inputState) {
            if (!inputState.hidden) {
                visibleFilters.push(inputState);
            }
        });

        // show/hide remove-all-button according to the number of visible quickfilters
        if (find(selectors.removeAllButton)) {
            if (visibleFilters.length === 0) {
                find(selectors.removeAllButton).setAttribute('hidden', true);
            } else {
                find(selectors.removeAllButton).removeAttribute('hidden');
            }
        }
    }

    function createQuickFilter(inputState) {
        let template = find(selectors.template).children[0];
        let newButtonElement = template.cloneNode(true);

        let decodeHTML = function (html) {
            let txt = document.createElement('textarea');
            txt.innerHTML = html;
            return txt.value;
        };

        findIn(selectors.text, newButtonElement).innerHTML = decodeHTML(inputState.text);
        return newButtonElement;
    }
}